.dragndrop .gu-transit {
  opacity: 0.2;
}
.dragndrop .selected > div {
  background-color: #e0e6ed;
}
.dark .dragndrop .selected > div {
  background-color: #0e1726;
}
.icon-change .left svg.feather-heart,
.icon-change .right svg.feather-star {
  display: none;
}
.dragndrop .sortable-chosen {
  @apply bg-white dark:bg-black;
}
