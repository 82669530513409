.dark .flatpickr-calendar {
  border: 1px solid #0e1a2c;
  background: #0e1a2c;
  box-shadow: none;
}

.dark .flatpickr-calendar.arrowTop:after {
  border-bottom-color: #0e1a2c;
}

.dark .flatpickr-calendar.arrowBottom:after {
  border-top-color: #0e1a2c;
}

.dark .flatpickr-calendar .flatpickr-months .flatpickr-prev-month svg,
.dark .flatpickr-calendar .flatpickr-months .flatpickr-next-month svg {
  fill: #bfc9d4;
}

.flatpickr-current-month {
  font-size: 16px;
  padding: 3px 0 0 0;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.dark .flatpickr-calendar .flatpickr-months .flatpickr-monthDropdown-months {
  color: #bfc9d4;
}

.dark
  .flatpickr-calendar
  .flatpickr-months
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: #1b2e4b;
}

.dark .flatpickr-calendar .flatpickr-months input.cur-year {
  color: #bfc9d4;
  height: 31px;
}

.dark .flatpickr-calendar .flatpickr-months .numInputWrapper span {
  height: 26%;
}

.dark .flatpickr-calendar .flatpickr-months .numInputWrapper span.arrowUp {
  top: 10px;
}

.dark
  .flatpickr-calendar
  .flatpickr-months
  .numInputWrapper
  span.arrowUp:after {
  border-bottom-color: #bfc9d4;
}

.dark .flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #bfc9d4;
}

.dark .flatpickr-calendar .flatpickr-months .numInputWrapper span .arrowDown {
  top: 34%;
}

.dark
  .flatpickr-calendar
  .flatpickr-months
  .numInputWrapper
  span
  .arrowDown:after {
  border-top-color: #bfc9d4;
}

.dark .flatpickr-calendar .flatpickr-day {
  color: #888ea8;
  font-weight: 500;
}

.dark .flatpickr-calendar .flatpickr-day:hover {
  background: #191e3a;
  border-color: #191e3a;
}

.dark .flatpickr-calendar .flatpickr-day.flatpickr-disabled {
  color: rgba(136, 142, 168, 0.22);
}

.dark .flatpickr-calendar .flatpickr-day.prevMonthDay,
.dark .flatpickr-calendar .flatpickr-day.nextMonthDay {
  color: rgba(136, 142, 168, 0.22) !important;
}

.dark .flatpickr-calendar .flatpickr-day.selected {
  background: #009688;
  color: #0e1726;
  border-color: #009688;
  font-weight: 700;
}

.dark .flatpickr-calendar .flatpickr-day.today {
  border-color: #009688;
}

.dark .flatpickr-calendar .flatpickr-day.today:hover {
  border-color: #0e1726;
  background: #0e1726;
  color: #fff;
}

.dark .flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid #243146 !important;
}

.dark .flatpickr-calendar .flatpickr-time {
  border: 1px solid #243146;
}

.dark .flatpickr-calendar .flatpickr-time input,
.dark .flatpickr-calendar .flatpickr-time .flatpickr-time-separator,
.dark .flatpickr-calendar .flatpickr-time .flatpickr-am-pm {
  color: #888ea8;
}

.dark .flatpickr-time input:hover,
.dark .flatpickr-time .flatpickr-am-pm:hover,
.dark .flatpickr-time input:focus,
.dark .flatpickr-time .flatpickr-am-pm:focus {
  background: transparent;
  --tw-ring-color: transparent;
}

.dark .flatpickr-calendar .flatpickr-time input:hover,
.dark .flatpickr-calendar .flatpickr-time .flatpickr-am-pm:hover {
  background-color: #0e1726;
}

.dark .flatpickr-calendar .flatpickr-time .numInputWrapper span.arrowUp::after {
  border-bottom-color: #009688;
}

.dark
  .flatpickr-calendar
  .flatpickr-time
  .numInputWrapper
  span.arrowDown::after {
  border-top-color: #009688;
}

.dark .flatpickr-calendar.arrowTop:before {
  border-bottom-color: #1b2e4b;
}

.dark .flatpickr-calendar.arrowBottom::before {
  border-top-color: #1b2e4b;
}

.dark .numInputWrapper span {
  height: 26%;
}

.dark .numInputWrapper span.arrowUp {
  top: 10px;
}

.dark .numInputWrapper span.arrowUp:after {
  border-bottom-color: #bfc9d4;
}

.dark .numInputWrapper span .arrowDown {
  top: 34%;
}

.dark .numInputWrapper span .arrowDown:after {
  border-top-color: #bfc9d4;
}

.flatpickr-calendar:before {
  border-width: 11px;
}

.flatpickr-calendar:after {
  border-width: 9px;
}

.dark span.flatpickr-weekday {
  color: #888ea8;
}

.dark .flatpickr-calendar .flatpickr-day.today.inRange {
  border-color: #e6e6e6;
}

.dark .flatpickr-day.inRange {
  background-color: #060818;
  border-color: #060818;
  box-shadow: -5px 0 0 #060818, 5px 0 0 #060818;
}
